<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Tạo mới sự kiện"
          header-tag="header"
      >
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="campaign_title"
                  label="Tên sự kiện(*):"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.name"
                    placeholder="Nhập tên sự kiện..."
                    :class="[{ 'is-invalid': form.errors.has('name') }]"
                ></b-form-input>
                <has-error :form="form" field="name"></has-error>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group
                  id="client_id"
                  label="MerchantID(*):"
                  label-for="input-1"

              >
                <b-form-select v-model="form.client_id" :class="[{ 'is-invalid': form.errors.has('client_id') }]">
                  <option value="">--Chọn--</option>
                  <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
                </b-form-select>
                <has-error :form="form" field="client_id"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                  id="type"
                  label="Loại(*):"
                  label-for="input-1"

              >
                <b-form-select v-model="form.type" :class="[{ 'is-invalid': form.errors.has('type') }]">
                  <option value="">--Chọn--</option>
                  <option value="ONCE">Chỉ nhận 1 lần</option>
                  <option value="MANY">Nhận nhiều lần</option>
                </b-form-select>
                <has-error :form="form" field="type"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-4" v-show="form.type == 'MANY'">
              <b-form-group
                  id="campaign_title"
                  label="Số lần nhận quà:"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.num_receive_gift"
                    placeholder="số lần nhận quà..."
                    :class="[{ 'is-invalid': form.errors.has('num_receive_gift') }]"
                ></b-form-input>
                <has-error :form="form" field="num_receive_gift"></has-error>
              </b-form-group>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-1">
              Kích hoạt
            </div>
            <div class="col-md-3">

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="1"
                  unchecked-value="0"
                  size="lg"
              >

              </b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="campaign_desc"
                  label="Mô tả (nếu có)"
                  label-for="input-1"
              >
                <b-form-textarea
                    id="textarea"
                    v-model="form.desc"
                    placeholder="Nhập mô tả..."
                    rows="3"
                    max-rows="6"
                    :class="[{ 'is-invalid': form.errors.has('desc') }]"
                ></b-form-textarea>
                <has-error :form="form" field="desc"></has-error>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-button variant="success" v-if="!is_edit" style="margin-right: 10px;" @click="onSubmit">Tạo mới</b-button>
              <b-button variant="warning" v-else style="margin-right: 10px;" @click="onUpdate">Cập nhật</b-button>
              <router-link
                  :to="'/combo-voucher/event'"
              >
                <b-button variant="outline-primary">Hủy</b-button>
              </router-link>

            </div>
          </div>

        </b-form>
      </b-card>


    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Form } from 'vform';
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import {
  HasError,
} from 'vform/src/components/bootstrap5'
import Vue from "vue";
Vue.component(HasError.name, HasError)

import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

Vue.component("multiselect", Multiselect);
export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {},
  data() {
    return {
      form: new Form({
        id: '',
        name: '',
        code: '',
        desc: '',
        client_id: '',
        type: '',
        num_receive_gift: '',
        status: 0
      }),
      show: true,
      is_edit: false,
      clients: [],

    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí sự kiện ", route: "/combo-voucher/event" },
      { title: "Thêm sự kiện" },
    ]);
  },

  methods: {
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    onSubmit () {
      //this.form.axios = ApiService;
      this.form.submit('post', '/combo-voucher/event')
      .then(({data}) => {
        if (1 === data.error_code) {
          this.$notify({
            group: "foo",
            type: "error",
            title: data.message,
          });
        } else {
          this.$notify({
            group: "foo",
            type: "success",
            title: data.message,
          });
          this.$router.push({ name: "combo_voucher_campaign" });
        }
      })
      .catch((error) => {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      })
    },
    async loadEvent (id) {
      try {
        let response = await WalletCampaignRepository.getEvent(id);
        if (response.data.error_code === 0) {
          this.form.fill(response.data.data)
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    onUpdate () {
      this.form.submit('post', '/combo-voucher/event/'+this.form.id)
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "combo_voucher_campaign" });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    }
  },
  created() {
    this.getClients();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadEvent(this.$route.params.id);
    }
  },
};
</script>


